<template>
    <section class="section__container container container_active separator-when-scrolling plr__12 pb__12">
<!--        <div class="field-group mb__12">-->
<!--            <label class="field-group__label" for="locator__valid-until">Period</label>-->
<!--            <input class="field-group__input" type="text" id="period123" name="locator__valid-until"-->
<!--                   placeholder="Select a period">-->
<!--            <span class="field-group__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="input__date"/></span> </span>-->
<!--        </div>-->

        <div class="card pfull__16 mb__12">
            <div class="parameter mb__20">
                <span class="parameter__icon icon icon_type_circle icon_theme_secondary">
                    <span class="icon__symbol"><SvgIcon class="icon" name="circle__message"/></span>
                </span>
                <h5 class="parameter__label">{{ $t('Unit.Last message') }}</h5>
                <p class="parameter__value">20 seconds ago</p>
            </div>

            <div class="parameter mb__20">
                <span class="parameter__icon icon icon_type_circle">
                    <span class="icon__symbol"><SvgIcon class="icon" name="circle__pin"/></span>
                </span>
                <h5 class="parameter__label">{{ $t('Unit.Address') }}</h5>
                <p class="parameter__value">
                    5229, Torrington Circle, Devonshire III at White Marsh, White Marsh, Baltimore County
                    <button class="parameter__button button button_view_icon button_theme_primary"
                            @click="copyUnitAddress"
                    >
                        <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__copy"/></span> </span>
                    </button>
                </p>
            </div>

            <div class="parameter">
                <span class="parameter__icon icon icon_type_circle">
                    <span class="icon__symbol"><SvgIcon class="icon" name="circle__coords"/></span>
                </span>
                <h5 class="parameter__label">{{ $t('Unit.Coords') }}</h5>
                <p class="parameter__value">
                    26.068855, -80.116418 (Last valid coordinates)
                    <button class="parameter__button button button_view_icon button_theme_primary">
                        <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__copy"/></span> </span>
                    </button>
                </p>
            </div>
        </div>

        <div class="card card_type_parameters-2-columns pfull__16 mb__12">
            <div class="parameter">
                <span class="parameter__icon icon icon_type_circle">
                    <span class="icon__symbol"><SvgIcon class="icon" name="circle__parking"/></span>
                </span>
                <h5 class="parameter__label">{{ $t('Unit.Duration of parking') }}</h5>
                <p class="parameter__value">58 minutes</p>
            </div>

            <div class="parameter">
                <span class="parameter__icon icon icon_type_circle">
                    <span class="icon__symbol"><SvgIcon class="icon" name="circle__satellit"/></span>
                </span>
                <h5 class="parameter__label">{{ $t('Unit.Satellites') }}</h5>
                <p class="parameter__value">50</p>
            </div>

            <div class="parameter mt__20">
                <span class="parameter__icon icon icon_type_circle">
                    <span class="icon__symbol"><SvgIcon class="icon" name="circle__steering-wheel"/></span>
                </span>
                <h5 class="parameter__label">{{ $t('Unit.Driver') }}</h5>
                <p class="parameter__value">Driver 21</p>
            </div>

            <div class="parameter mt__20">
                <span class="parameter__icon icon icon_type_circle">
                    <span class="icon__symbol"><SvgIcon class="icon" name="circle__mileage"/></span>
                </span>
                <h5 class="parameter__label">{{ $t('Unit.Mileage') }}</h5>
                <p class="parameter__value">85387.6 miles</p>
            </div>

            <div class="parameter mt__20">
                <span class="parameter__icon icon icon_type_circle">
                    <span class="icon__symbol"><SvgIcon class="icon" name="circle__hourglass"/></span>
                </span>
                <h5 class="parameter__label">{{ $t('Unit.Engine hours') }}</h5>
                <p class="parameter__value">345 h</p>
            </div>

            <div class="parameter mt__20">
                <span class="parameter__icon icon icon_type_circle">
                    <span class="icon__symbol"><SvgIcon class="icon" name="circle__speed"/></span>
                </span>
                <h5 class="parameter__label">{{ $t('Unit.Max permissible speed') }}</h5>
                <p class="parameter__value">60 miles</p>
            </div>
        </div>

        <div class="card card_type_table-2-columns ptb__4 mb__12">
            <table class="table">
                <tr>
                    <th>Panic Button:</th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>Aftertreatment 1 Diesel Exhaust Fluid Tank 1 Level (%):</th>
                    <td>97</td>
                </tr>
                <tr>
                    <th>Amber warning lamp (DTC):</th>
                    <td>1</td>
                </tr>
                <tr>
                    <th>Cruise Control Active:</th>
                    <td>On</td>
                </tr>
                <tr>
                    <th>Diesel Particulate Filter Active Regeneration Status:</th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>Diesel Particulate Filter Lamp Command:</th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>Diesel Particulate Filter Status:</th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>DPF Thermal Management Active:</th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>Engine Coolant Level (%):</th>
                    <td>100</td>
                </tr>
                <tr>
                    <th>Engine Coolant Temperature C:</th>
                    <td>190.4 °F</td>
                </tr>
            </table>
        </div>

        <div class="card card_type_table-2-columns ptb__4">
            <table class="table">
                <tr>
                    <th>Name:</th>
                    <td>Name object</td>
                </tr>
                <tr>
                    <th>Brand:</th>
                    <td>Mitsubishi</td>
                </tr>
                <tr>
                    <th>Year:</th>
                    <td>2018</td>
                </tr>
                <tr>
                    <th>VIN:</th>
                    <td>865731040025235</td>
                </tr>
                <tr>
                    <th>Number plate:</th>
                    <td>BFW 977</td>
                </tr>
                <tr>
                    <th>Custom field 1:</th>
                    <td>Value</td>
                </tr>
                <tr>
                    <th>Custom field 2:</th>
                    <td>Value</td>
                </tr>
                <tr>
                    <th>Custom field 3:</th>
                    <td>Value</td>
                </tr>
                <tr>
                    <th>Custom field 4:</th>
                    <td>Value</td>
                </tr>
                <tr>
                    <th>Custom field 5:</th>
                    <td>Value</td>
                </tr>
            </table>
        </div>
    </section>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: 'UnitInfo_Info',
        props: [
            'unitId',
        ],
        components: {
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "unitsByIds",
            ]),
        },
        methods: {
            ...mapActions([
                "setSectionInfo",
            ]),
            copyUnitAddress(){
                return false
            },
        },
        mounted() {
        }
    }
</script>
